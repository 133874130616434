import {provideFluentDesignSystem, fluentSkeleton} from "@fluentui/web-components";
import styles from "./Chat.module.css";
import skeletonStyles from "./ChatSkeleton.module.css";
import questionStyles from "../../components/QuestionInput/QuestionInput.module.css";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'fluent-skeleton': any;
        }
    }
}

export const ChatSkeleton: React.FC = () => {
  provideFluentDesignSystem().register(fluentSkeleton());

  return (
    <div className={styles.container}>
        <div className={styles.chatRoot}>
            <div className={styles.chatContainer}>
                <div className={styles.chatMessageStream}>
                    <div className={styles.chatMessageGpt}>
                        <fluent-skeleton 
                            id={skeletonStyles.greetingText} 
                            shape="rect" 
                            shimmer={true}>    
                        </fluent-skeleton>
                    </div>
                </div>
                <div className={styles.chatInput}>
                    
                    <div className={`${questionStyles.questionInputContainer} ${skeletonStyles.flexContainer}`}>
                        <div className={questionStyles.questionInputTextArea}>
                            <div className={skeletonStyles.textFieldWrapper}>
                                <fluent-skeleton 
                                    id={skeletonStyles.questionInputText} 
                                    shape="rect" 
                                    shimmer={true}>
                                </fluent-skeleton>
                            </div>
                        </div>
                        <div className={questionStyles.questionInputSendButtonContainer}>
                                <fluent-skeleton 
                                    id={skeletonStyles.questionInputSendButton} 
                                    shape="circle" 
                                    shimmer={true}>
                                </fluent-skeleton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <p className={skeletonStyles.chatFooterContainer}>
                <fluent-skeleton 
                    id={skeletonStyles.chatFooter} 
                    shape="rect" 
                    shimmer={true}>
                </fluent-skeleton>
            </p>
        </div>
    </div>
  )
};

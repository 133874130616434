import {ApplicationInsights, IMetricTelemetry, ITelemetryItem, SeverityLevel} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

const reactPlugin: ReactPlugin | any = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
});

//appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env:ITelemetryItem) => {
    env.tags = env.tags || [];
});

export const trackException = (error: string) => {
    appInsights.trackException({ error: new Error(error), severityLevel: SeverityLevel.Error });
}

export const  trackTrace = (message: string, additionalProperties?:object) => {
    appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Information }, additionalProperties);
}

export const trackEvent = (eventName: string, additionalProperties?:object) => {
    appInsights.trackEvent({ name: eventName },additionalProperties);
}


export const trackMetrics = (name: string, average:number,additionalProperties?:object) => {
  const metricData:IMetricTelemetry = {
    name: name,
    average: average,
    sampleCount: 1
  };
  
  appInsights.trackMetric(metricData, additionalProperties);
}

export { reactPlugin, appInsights };
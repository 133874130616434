import ChatContextButton from './ChatContextButton';
import { useChatContext } from '../../state/ChatContextProvider';
import { Stack } from '@fluentui/react';
import { useTranslation } from '../../state/LanguageProvider';

const ChatContextSelection = () => {
    const { chatContextKey, setChatContextKey, availableChatContexts } = useChatContext();
    const { getTranslation } = useTranslation();

    return (
        <div>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                { Object.entries(availableChatContexts).map(([key, text]) => (
                    <ChatContextButton
                        label={`${getTranslation('help_me_with')} ${text}`}
                        isActive={chatContextKey === key}
                        onClick={() => setChatContextKey(key)}
                    />
                ))}
            </Stack>
        </div>
    );
};

export default ChatContextSelection;

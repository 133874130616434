import ErrorIcon from "../../assets/Error_Purple.svg"
import styles from "./Unauthenticated.module.css"

export const Unauthenticated = () => {
    return (
        <div className={styles.unauthenticatedContainer}>
            <div>
                <img className={styles.unauthenticatedIcon} src={ErrorIcon}  alt="Error icon" aria-hidden="true"/>
            </div>
    
            <div>
                <h1 className={styles.unauthenticatedTitle}>OOPS! YOU`RE UNAUTHENTICATED</h1>
                <h2 className={styles.unauthenticatedSubtitle}>Please make sure you`re authenticated via azure and try reopening the chat on a new browser</h2>
                <h2 className={styles.unauthenticatedSubtitle}>If the issue persists, contact Guide Team at GuideTeam@telushealth.com </h2>
            </div>
        </div>
    );
};

import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { getChatContexts, Language } from '../api';
import { useLanguage } from './LanguageProvider';

interface ChatContextProps {
    chatContextKey: string;  
    contextExists: boolean;
    setChatContextKey: (context: string) => void;
    availableChatContexts: Record<string, string>;  
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

export const ChatContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [chatContextKey, setChatContextKey] = useState<string>('AZURE_SEARCH_INDEX'); // Set default to AZURE_SEARCH_INDEX in case there is no multi context.
    const [availableChatContexts, setAvailableChatContexts] = useState<Record<string, string>>({});
    const [contextExists, setContextExists] = useState<boolean>(false);
    const { language } = useLanguage();
    
    
    const fetchContext = async (lang: Language) => {
        try {
            const contexts = await getChatContexts(lang); 
            setAvailableChatContexts(contexts);
            setContextExists(Object.keys(contexts).length > 0)
    
        } catch (error) {
            console.error('Error fetching context:', error);
        }
    };

    useEffect(() => {
        fetchContext(language);
    }, [language]);

    return (
        <ChatContext.Provider value={{ chatContextKey, setChatContextKey, contextExists, availableChatContexts }}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChatContext = (): ChatContextProps => {
    const context = useContext(ChatContext);
    if (context === undefined) {
        throw new Error('useChatContext must be used within a ChatContextProvider');
    }
    return context;
};

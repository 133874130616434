import styles from "./UserGuide.module.css";
import ClearHistory from "../../assets/Clear_History.jpg";
import StopGeneratingEN from "../../assets/Stop_Generating.jpg";
import StopGeneratingFR from "../../assets/Stop_Generating_FR.jpg";
import Regenerate from "../../assets/Regenerate.jpg";
import { SendFilled, SendRegular, CopyRegular, CopyFilled, DeleteFilled, ArrowRepeatAllRegular } from "@fluentui/react-icons";
import { IconButton, IIconProps} from "@fluentui/react";

import { useTranslation} from "../../state/LanguageProvider";

const UserGuide = () => {
    const { getTranslation } = useTranslation();

    const StopGeneratingImages = {
        "StopGeneratingEN": StopGeneratingEN,
        "StopGeneratingFR": StopGeneratingFR,
    };
    const StopGeneratingImage = StopGeneratingImages[getTranslation("stop_generating_image") as keyof typeof StopGeneratingImages];

    return (
        <div>  
            <p>{getTranslation('ug_intro_paragraph_1')}</p>  
            <p>{getTranslation('ug_intro_paragraph_2')}</p>  
            <p>{getTranslation('ug_intro_paragraph_3')}</p>  
            <p><strong>{getTranslation('ug_examples_heading')}</strong></p>  
            <p>{getTranslation('ug_example_1')}</p>  
            <p>{getTranslation('ug_example_2')}</p>  
            <p>{getTranslation('ug_example_3')}</p>  
            <h2>{getTranslation('ug_features_heading')}</h2>
            <table className={styles.userGuideTable}>  
                <tbody>  
                <tr className={styles.headerRow}>  
                    <th className={styles.headerRowTextContainer}>  
                        <p className={styles.headerRowTextCentered}>  
                        <strong>{getTranslation('ug_table_header_active_state')}</strong>  
                        </p>  
                    </th>  
                    <th className={styles.headerRowTextContainer}>  
                        <p className={styles.headerRowTextCentered}>  
                        <strong>{getTranslation('ug_table_header_inactive_state')}</strong>  
                        </p>  
                    </th>  
                    <th className={styles.headerRowTextContainer}>  
                        <p>  
                        <strong>{getTranslation('ug_table_header_purpose')}</strong>  
                        </p>  
                    </th>  
                </tr>  
                <tr>  
                    <td>
                        <img src={ClearHistory} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td className={styles.noHover}>{getTranslation('ug_not_available')}</td> 
                    <td>{getTranslation('ug_clear_chat_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                        <SendFilled className={styles.iconStyle}/>
                    </td>
                    <td className={styles.noHover}>
                        <SendRegular className={styles.iconStyle}/>
                    </td>  
                    <td>{getTranslation('ug_send_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover} id={styles.stopGenerating}>
                        <img src={StopGeneratingImage} className={styles.ugImg} aria-hidden="true"/>
                    </td>  
                    <td className={styles.noHover}>{getTranslation('ug_not_available')}</td>  
                    <td>{getTranslation('ug_stop_generating_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                        <ArrowRepeatAllRegular className={styles.iconStyle}/> 
                    </td>  
                    <td className={styles.noHover}>{getTranslation('ug_not_available')}</td>  
                    <td>{getTranslation('ug_regenerate_response_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                    <IconButton
                        iconProps={{ iconName: 'LikeSolid' }}
                        ariaLabel="LikeSolid"
                        className={styles.iconStyle}
                        styles={{   
                            root: {   
                              height: '50px',   
                              width: '50px'   
                            },  
                            icon: {  
                              fontSize: '20px',  
                            } 
                        }}
                    />
                    </td>  
                    <td className={styles.noHover}>
                    <IconButton
                        iconProps={{ iconName: 'Like' }}
                        ariaLabel="Like"
                        className={styles.iconStyle}
                        styles={{   
                            root: {   
                              height: '50px',   
                              width: '50px'   
                            },  
                            icon: {  
                              fontSize: '20px',  
                            } 
                        }}
                    />
                    </td>  
                    <td>{getTranslation('ug_thumbs_up_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                    <IconButton
                        iconProps={{ iconName: 'DislikeSolid' }}
                        ariaLabel="DislikeSolid"
                        className={styles.iconStyle}
                        styles={{   
                            root: {   
                              height: '50px',   
                              width: '50px'   
                            },  
                            icon: {  
                              fontSize: '20px',  
                            } 
                        }}
                    />
                    </td>  
                    <td className={styles.noHover}>
                    <IconButton
                        iconProps={{ iconName: 'Dislike' }}
                        ariaLabel="Dislike"
                        className={styles.iconStyle}
                        styles={{   
                            root: {   
                              height: '50px',   
                              width: '50px'   
                            },  
                            icon: {  
                              fontSize: '20px',  
                            } 
                        }}
                    />
                    </td>  
                    <td>{getTranslation('ug_thumbs_down_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                        <CopyFilled className={styles.iconStyle} />
                    </td>  
                    <td className={styles.noHover}>
                        <CopyRegular className={styles.iconStyle} />
                    </td>  
                    <td>{getTranslation('ug_copy_to_clipboard_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                        <IconButton iconProps={{ iconName: 'Download' }} 
                            aria-label="Download source" 
                            className={styles.iconStyle} 
                            styles={{   
                                root: {   
                                  height: '50px',   
                                  width: '50px'   
                                },  
                                icon: {  
                                  fontSize: '20px',  
                                } 
                            }}
                        />
                            
                    </td>  
                    <td className={styles.noHover}>{getTranslation('ug_not_available')}</td>  
                    <td>{getTranslation('ug_download_source_button')}</td>  
                </tr>  
                <tr>  
                    <td className={styles.noHover}>
                        <IconButton iconProps={{ iconName: 'Cancel' }} 
                            aria-label="Close citations panel" 
                            className={styles.iconStyle} 
                            styles={{   
                                root: {   
                                  height: '50px',   
                                  width: '50px'   
                                },  
                                icon: {  
                                  fontSize: '20px',  
                                } 
                            }}    
                        />
                    </td>  
                    <td className={styles.noHover}>{getTranslation('ug_not_available')}</td>  
                    <td>{getTranslation('ug_close_citation_panel_button')}</td>  
                </tr>  
                </tbody>  
            </table>  
        </div>  
        
        );
};

export default UserGuide;


import React from 'react';
import styles from './ChatContextButton.module.css';

type ChatContextButtonProps = {
    label: string;
    isActive: boolean;
    onClick: () => void;
};

const ChatContextButton: React.FC<ChatContextButtonProps> = ({ label, isActive, onClick }) => {
    return (
        <div
            className={`${styles.chatContextButton} ${isActive && styles.focused}`}
            onClick={onClick}
        >
            {label}
        </div>
    );
};

export default ChatContextButton;

import { AskResponse, Citation } from "../../api";
import { cloneDeep } from "lodash-es";


type ParsedAnswer = {
    citations: Citation[];
    markdownFormatText: string;
    langfuseTraceId: string;
    answerId: string;
    isLastAnswer: boolean;
};

export function parseAnswer(answer: AskResponse): ParsedAnswer {
    let answerText = answer.answer;
    let isLastAnswer = answer.isLastAnswer;
    let langfuseTraceId = answer.langfuseTraceId || "Not Available";
    const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g);

    const lengthDocN = "[doc".length;

    let filteredCitations = [] as Citation[];
    let citationReindex = 0;
    citationLinks?.forEach(link => {
        // Replacing the links/citations with number
        let citationIndex = link.slice(lengthDocN, link.length - 1);
        let citation = cloneDeep(answer.citations[Number(citationIndex) - 1]) as Citation;
        if (!filteredCitations.find((c) => c.id === citationIndex)) {
          answerText = answerText.replaceAll(link, ` ^${++citationReindex}^ `);
          if(citation) {
            citation.id = citationIndex; // original doc index to de-dupe
            citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
            filteredCitations.push(citation);
          }
        }
    })

    return {
        citations: filteredCitations,
        markdownFormatText: answerText,
        langfuseTraceId: langfuseTraceId,
        answerId: answer.answerId,
        isLastAnswer: isLastAnswer
    };
}

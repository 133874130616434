import { provideFluentDesignSystem, fluentSkeleton } from "@fluentui/web-components";
import styles from "./Layout.module.css";
import layoutSkeletonStyles from "./LayoutSkeleton.module.css";
import { ChatSkeleton } from "../chat/ChatSkeleton"; 

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'fluent-skeleton': any;
        }
    }
}

const LayoutSkeleton: React.FC = () => {
  provideFluentDesignSystem().register(fluentSkeleton());

  return (
    <div className={styles.layout}>
        <header className={styles.header} role={"banner"}>
          <div className={styles.headerContainer}>
            <div className={styles.headerContainer1}>
              <div className={styles.headerTitleContainer}>
                <fluent-skeleton 
                  id={layoutSkeletonStyles.headerIcon} 
                  shape="rect" 
                  shimmer={true} 
                />
                <fluent-skeleton 
                  id={layoutSkeletonStyles.headerTitle} 
                  shape="rect" 
                  shimmer={true} 
                />
              </div>
            </div>
            <div className={styles.headerContainer2}>
              <fluent-skeleton 
                id={layoutSkeletonStyles.userGuideButton}
                shape="circle" 
                shimmer={true} 
              />
              <fluent-skeleton 
                id={layoutSkeletonStyles.iconLabel}
                shape="rect" 
                shimmer={true} 
              />
            </div>
          </div>
        </header>
        <ChatSkeleton />
    </div>
  );
};

export default LayoutSkeleton;

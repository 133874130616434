import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { getQueryParam, isValidLanguage } from '../utils/utils';
import { getTranslations } from '../api/api';
import { Language } from "../api/models";

interface LanguageContextProps {
    language: Language;
    setLanguage: (language: Language) => void;
    translations: Record<string, string>;
    isLoading: boolean;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const queryLang = getQueryParam('lang');
    const initialLanguage: Language = isValidLanguage(queryLang) ? (queryLang as Language) : 'en-ca';
    const [language, setLanguage] = useState<Language>(initialLanguage);
    const [translations, setTranslations] = useState<Record<string, string>>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchTranslations = async (lang: Language) => {
 
        try {
            const translations = await getTranslations(lang);
            setTranslations(translations);
        } catch (error) {
            console.error('Error fetching translations:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTranslations(language);
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage, translations, isLoading }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = (): LanguageContextProps => {
    const context = useContext(LanguageContext);
    if (context === undefined) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};

export const useTranslation = () => {
    const { translations, isLoading } = useLanguage();

    const getTranslation = (key: string) => {
        return translations[key] || key;
    };

    return { getTranslation, isLoading };
};
import { useEffect, useContext, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks"
import { FontIcon, IconButton, Stack, Text, IIconProps } from "@fluentui/react";
import { CopyRegular, CopyFilled, ArrowRepeatAll20Regular} from "@fluentui/react-icons";
import { LangfuseWeb } from "langfuse";
import styles from "./Answer.module.css";

import { AskResponse, Citation, FeedBack, FeedBackStatus } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'
import { trackEvent,trackMetrics,trackTrace } from "../../api/telemetric";
import { FeedBackButton } from "../FeedBackButton";

import { AppStateContext } from "../../state/AppProvider";
import { FrontendSettings } from "../../api";
import { useTranslation } from "../../state/LanguageProvider";

interface Props {
    answer: AskResponse;
    onCitationClicked: (citedDocument: Citation) => void;
    onRegenerateClicked: () => void;
}

export const Answer = ({
    answer,
    onCitationClicked,
    onRegenerateClicked
}: Props) => {
    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(false);
    const filePathTruncationLimit = 50;
    const { getTranslation } = useTranslation();

    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
    const {question} = answer;
    const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen);
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    const [copyText, setCopyText] = useState<string>("Copy");

    const appStateContext = useContext(AppStateContext);
    const frontEndSettings: FrontendSettings = appStateContext?.state.frontendSettings!;
    const {LANGFUSE_PUBLIC_KEY: publicKey = '', LANGFUSE_HOST:baseUrl = ''} = frontEndSettings;
   
    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded);
        toggleIsRefAccordionOpen();
    };

    const langfuseWeb = new LangfuseWeb({
      publicKey: publicKey,
      baseUrl: baseUrl
    });
  
    const handleLangfuseUserFeedback = async (responseStatus: FeedBack, feedback: string) => {

        const value = (responseStatus === FeedBackStatus.LIKED ? 1 : -1);
        
        await langfuseWeb.score({
            id: parsedAnswer.answerId,
            traceId: parsedAnswer.langfuseTraceId,
            name: "user-feedback",
            value: value,
            comment: feedback
          });
    }
      
    const handleFeedBackClick = (responseStatus: FeedBack, feedback: string) => {
        const regex = / \^[\d]+\^ /g;
        const botResponse = parsedAnswer.markdownFormatText.replace(regex, '');
        trackTrace(`FeedBack:${responseStatus} Bot Response:${botResponse}`);

        // For custom event - feedback status only
        const additionalProperties = { "question": question, "Bot Response": botResponse };
        trackEvent(typeof responseStatus == 'undefined' ? '':responseStatus,additionalProperties);
        trackMetrics(typeof responseStatus == 'undefined' ? '':responseStatus, 1, additionalProperties);

        // Log the user feedback as a langfuse trace score
        handleLangfuseUserFeedback(responseStatus, feedback);
    }

    const handleCopyClick = () => {
        const regex = / \^[\d]+\^ /g; // Define a regular expression to match the pattern for subscript
        navigator.clipboard.writeText(parsedAnswer.markdownFormatText.replace(regex, ''));
        setCopyClicked(true);
        setTimeout(function () {
            setCopyClicked(false);
        }, 4000);
        trackTrace(`User copied the response`)
    };

    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen);
        if (copyClicked) {
            setCopyText("Copied");
        }
    }, [isRefAccordionOpen, copyClicked]);

    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";

        if (citation.filepath && citation.chunk_id) {
            if (truncate && citation.filepath.length > filePathTruncationLimit) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength - 20)} - Part ${parseInt(citation.chunk_id) + 1}`;
            }
            else {
                citationFilename = `${citation.filepath} - Part ${parseInt(citation.chunk_id) + 1}`;
            }
        }
        else if (citation.filepath && citation.reindex_id) {
            citationFilename = `${citation.filepath} - Part ${citation.reindex_id}`;
        }
        else {
            citationFilename = `Citation ${index}`;
        }
        return citationFilename;
    }

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>

                {question !="" && answer.answer != "" && (
                    <Stack horizontal className={styles.answerHeader}>
                        <Stack.Item>
                            <Stack style={{ width: "100%" }} >
                                <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item className={styles.answerActionContainer}>

                            <FeedBackButton question={question} onFeedBackClicked={handleFeedBackClick} />

                            <div
                                className={styles.copyButtonContainer}
                                title={getTranslation('copy_response')}
                                role="button"
                                tabIndex={0}
                                aria-label={getTranslation('copy_response')}
                                onClick={handleCopyClick}
                                onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                            >
                                {copyClicked ? (
                                    <CopyFilled className={styles.copyButton} />
                                ) : (
                                    <CopyRegular className={styles.copyButton} />
                                )
                                }
                            </div>

                            {parsedAnswer.isLastAnswer && (
                                <Stack
                                horizontal
                                className={styles.regenerateContainer}
                                role="button"
                                title={getTranslation('regenerate_response')}
                                aria-label={getTranslation('regenerate_response')}
                                tabIndex={0}
                                onClick={onRegenerateClicked}
                                // onKeyDown={e => e.key === "Enter" || e.key === " " ? regenerateResponse(lastQuestionRef.current) : null}
                                >
                                    <ArrowRepeatAll20Regular className={styles.regenerateIcon} aria-hidden="true" />
                                </Stack>
                            )}

                        </Stack.Item>
                    </Stack>
                )}

                <Stack.Item grow>
                    <ReactMarkdown
                        linkTarget="_blank"
                        remarkPlugins={[remarkGfm, supersub]}
                        children={parsedAnswer.markdownFormatText}
                        className={styles.answerText}
                    />
                </Stack.Item>

                <Stack horizontal className={styles.answerFooter}>
                    {!!parsedAnswer.citations.length && (
                        <Stack.Item
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleIsRefAccordionOpen() : null}
                        >
                            <Stack style={{ width: "100%" }} >
                                <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                    <Text
                                        className={styles.accordionTitle}
                                        onClick={toggleIsRefAccordionOpen}
                                        aria-label="Open references"
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <span>
                                            {
                                                parsedAnswer.citations.length > 1 ? parsedAnswer.citations.length + getTranslation('references') 
                                                : "1" + getTranslation('reference')
                                            }
                                        </span>
                                    </Text>
                                    <FontIcon className={styles.accordionIcon}
                                        onClick={handleChevronClick} iconName={chevronIsExpanded ? 'ChevronDown' : 'ChevronRight'}
                                    />
                                </Stack>

                            </Stack>
                        </Stack.Item>
                    )}
                    {question !="" && answer.answer != "" && (
                        <Stack.Item className={styles.answerDisclaimerContainer}>
                         <span className={styles.answerDisclaimer}>{getTranslation('ai_content_warning')}</span>
                        </Stack.Item>)
                    }
                </Stack>
                {chevronIsExpanded &&
                    <div className={styles.references}>
                        {parsedAnswer.citations.map((citation, idx) => {
                            return (
                                <span
                                    title={createCitationFilepath(citation, ++idx)}
                                    tabIndex={0}
                                    role="link"
                                    key={idx}
                                    onClick={() => onCitationClicked(citation)}
                                    onKeyDown={e => e.key === "Enter" || e.key === " " ? onCitationClicked(citation) : null}
                                    className={styles.citationContainer}
                                    aria-label={createCitationFilepath(citation, idx)}
                                >
                                    <div className={styles.citation}>{idx}</div>
                                    {createCitationFilepath(citation, idx, true)}
                                </span>);
                        })}
                    </div>
                }
            </Stack>
        </>
    );
};

import React, { useState, useContext } from "react";
import { IconButton, IIconProps, Popup, Layer, Overlay, mergeStyleSets, FocusTrapZone, DefaultButton, TextField } from "@fluentui/react";
import { FeedBack, FeedBackStatus} from "../../api";
import styles from "./FeedBackButton.module.css";
import { useBoolean } from '@fluentui/react-hooks';
import { FeedBackList } from "./FeedBackList";
import { trackEvent } from "../../api/telemetric";
import { useTranslation } from "../../state/LanguageProvider";

const LikeIconRegular: IIconProps = { iconName: 'Like' };
const DisLikeIconRegular: IIconProps = { iconName: 'Dislike' };
const LikeIconFilled: IIconProps = { iconName: 'LikeSolid' };
const DisLikeIconFilled: IIconProps = { iconName: 'DislikeSolid' };

interface Props {
    onFeedBackClicked: (responseStatus: FeedBack, feedback: string) => void;
    question:string
}

const popupStyles = mergeStyleSets({
    root: {
      background: 'rgba(0, 0, 0, 0.2)',
      bottom: '0',
      left: '0',
      position: 'fixed',
      right: '0',
      top: '0',
    },
    content: {
      background: 'white',
      left: '50%',
      minWidth: '300px',
      maxWidth: '600px',
      width: 'calc(100% - 100px)',
      padding: '0 2em 2em',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      fontFamily: "HelveticaNowTELUS",
    },
  });

  
export const FeedBackButton = ({
    onFeedBackClicked,
    question
}: Props) => {

    const [status, updateStatus] = useState<FeedBack>('DEFAULT');
    const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);
    const [feedbackOptions, setFeedbackOptions] = useState<any[]>([]);
    const [feedbackReason, setFeedBackReason] = useState<string>('');
    const { getTranslation } = useTranslation();
    const handleFeedBackClick = (responseStatus: FeedBack, question:string) => {
        updateStatus(responseStatus)
        onFeedBackClicked(responseStatus, "")
    }

    const onFeedbackSelectionChange=(feedBackOptions:any[])=>{
        setFeedbackOptions(feedBackOptions);
    }

    const onReasonChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setFeedBackReason(newValue || "");
    };

    const onFeedbackSubmit = ()=>{
        const selectedReasons = feedbackOptions.filter(option=>option.selected).map(ele=>ele.text);
        const additionalProperties = { "question": question, "Reason": `Selected Reasons: ${selectedReasons.join(',')} User FeedBack: ${feedbackReason}` };
        updateStatus(FeedBackStatus.DISLIKED);
        onFeedBackClicked(FeedBackStatus.DISLIKED, additionalProperties.Reason);
        trackEvent( "DISLIKEFEEDBACK" ,additionalProperties);
        setFeedBackReason("");
        hidePopup();
    }

    const cancel = () => {
        setFeedBackReason("");
        hidePopup();
    }

    return (
        <>
            <IconButton
                iconProps={status === FeedBackStatus.LIKED ? LikeIconFilled : LikeIconRegular}
                title={getTranslation('like')}
                ariaLabel={getTranslation('like')}
                onClick={() => handleFeedBackClick(FeedBackStatus.LIKED, question)}
                className={styles.feedBackIconButtonStyle}
            />
            <IconButton
                iconProps={status === FeedBackStatus.DISLIKED ? DisLikeIconFilled : DisLikeIconRegular}
                title={getTranslation('dislike')}
                ariaLabel={getTranslation('dislike')}
                onClick={showPopup}
                className={styles.feedBackIconButtonStyle}
            />
            {isPopupVisible && (
                <Layer>
                    <Popup
                        role="dialog"
                        aria-modal="true"
                        onDismiss={hidePopup}
                        className={popupStyles.root}
                    >
                        
                        <Overlay onClick={hidePopup} />
                        <FocusTrapZone>
                        <div role="document" className={popupStyles.content}>
                            <h2>{getTranslation('feedback_form')}</h2>
                            <FeedBackList onSelectionChange={onFeedbackSelectionChange}/>
                            <div className={styles.feedBackTextField}>
                                {feedbackReason.length} {getTranslation('char_limit')}
                                <TextField
                                    multiline
                                    resizable={false}
                                    onChange={onReasonChange}
                                    maxLength={250}
                                    style={{fontFamily: "HelveticaNowTELUS", fontFeatureSettings: '"ss08" on, "ss04" on, "ss01" on, "salt" on',}}
                                />
                            </div>
                           
                            <DefaultButton onClick={onFeedbackSubmit} className={styles.feedBackButtonStyle}>{getTranslation('submit')}</DefaultButton> 
                            <DefaultButton onClick={cancel} className={styles.feedBackButtonStyle}>{getTranslation('cancel')}</DefaultButton>
                        </div>
                        </FocusTrapZone>
                    </Popup>
                </Layer>
            )}
        </>
    );
};